import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
// import { GatsbyImage, getImage, getImageData } from "gatsby-plugin-image"
// import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const WorkOpenerTemplate = ({ 
  title,
  content, 
  contentComponent, 
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              {/* <FullWidthImage img={ getImage(featuredimage) } height={ "75vh" } imgPosition="center"/> */}
              {/* <GatsbyImage image={ getImage(featuredimage) } /> */}
              <PageContent className="content" content={content} />
              <div className="columns is-multiline" >
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WorkOpenerTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const WorkOpener = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <WorkOpenerTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

WorkOpener.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WorkOpener;

export const workOpenerQuery = graphql`
  query WorkOpener($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
